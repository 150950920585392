import React from "react";
import { PageHeader, Row, Col, Tag } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
export const severityContext = React.createContext(
  localStorage.getItem("patient_status")
);
// "#83f52c" #green
// "#FFA15C" #orange
// "#FF3D37" #red
// "#FFEB5C" #yellow
function status(severity) {
  let patient_status = localStorage.getItem("patient_status").toLowerCase();
  switch (patient_status) {
    case "mild":
      return "mild";
    case "moderate":
      return "moderate";
    case "severe":
      return "severe";
    default:
      return "normal";
  }
}

function MiniDeet(props) {
  let location = useLocation();
  let history = useHistory();
  let logoutView = location.pathname === "/";
  let patientView = location.pathname.includes("/patient/");
  let detailView = location.pathname.includes("/detail");
  let patientName = localStorage.getItem("patientName");
  let patientAge = localStorage.getItem("patientAge");
  let patientSex = localStorage.getItem("patientSex");
  let legacy_id = localStorage.getItem("legacy_id");
  let days = localStorage.getItem("days");
  // let patient_status = localStorage.getItem("patient_status");

  if (!logoutView && !detailView && patientView) {
    return (
      <>
        <PageHeader
          className={`patient-page-header ${status()}`}
          onBack={() => history.push("/landing")}
          title={<span className="capitalize">{patientName}</span>}
          subTitle={
            <>
              {patientAge}/{patientSex}
            </>
          }
          extra={
            <>
              <span>Day #{days}</span>
              {/* <span>|</span> */}
              {/* <span>{patient_status === "" ? "Normal" : patient_status}</span> */}
              {legacy_id.includes("null") ? null : (
                <>
                  <span>|</span>
                  <span>{`IP number: ${legacy_id}`}</span>
                </>
              )}
            </>
          }
          ghost={false}
          backIcon={<ArrowLeftOutlined style={{ fontSize: "1.5rem" }} />}
        />
      </>
    );
  }
  return null;
}
export default MiniDeet;
