import React, { Component } from "react";
import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";

function RenderError(props) {
  const history = useHistory();
  const onClick = () => {
    history.push("/landing");
    setTimeout(() => {
      props.onBack();
    }, 500);
  };
  return (
    <Result
      status="500"
      title="Error"
      subTitle="Sorry, something went wrong."
      extra={
        <Button type="primary" onClick={() => onClick()}>
          Back Home
        </Button>
      }
      style={{ width: "100%" }}
    />
  );
}

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <RenderError
          {...this.props}
          onBack={() => this.setState({ hasError: false })}
        />
      );
    }

    return this.props.children;
  }
}
