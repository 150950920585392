import React, { Component, useState, Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import {
  Layout,
  Menu,
  Drawer,
  List,
  Button,
  message,
  Typography,
  Tag,
} from "antd";
import {
  TeamOutlined,
  BarChartOutlined,
  AlertOutlined,
  LogoutOutlined,
  SolutionOutlined,
  SettingOutlined,
  CloudDownloadOutlined,
  UserOutlined,
  DownOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import packageJson from "../package.json";
import ErrorBoundary from "./shared/errorBoundary";
import MiniDeet from "./components/miniDeet";
import { API_ROOT } from "./shared/api";
import { isEmpty, isUndefined } from "lodash";

// import Auth from "./pages/auth";
// import Dashboard from "./pages/dashboard";
// import Prescription from "./pages/prescription";
// import Patient from "./pages/patient";
// import ViewAllSPO2 from "./components/com/viewAllSPO2";
// import ViewAllPatients from "./components/com/viewAllPatients.jsx";
const Auth = lazy(() => import(/* webpackChunkName: 'Auth' */ "./pages/auth"));
const Landing = lazy(() =>
  import(/* webpackChunkName: 'Landing' */ "./pages/landing")
);
const PatientDetail = lazy(() =>
  import(/* webpackChunkName: 'PatientDetail' */ "./pages/patientDetailEnh")
);
const Demographic = lazy(() =>
  import(/* webpackChunkName: 'Demographic' */ "./components/demographic")
);
const Hos = lazy(() =>
  import(/* webpackChunkName: 'Hos' */ "./components/historyOfSymptoms")
);
const Comorbidity = lazy(() =>
  import(/* webpackChunkName: 'Comorbidity' */ "./components/comorbidity")
);
const Hospitalization = lazy(() =>
  import(
    /* webpackChunkName: 'Hospitalization' */ "./components/hospitalization"
  )
);
const ListOrders = lazy(() =>
  import(
    /* webpackChunkName: 'ListOrders' */ "./components/investigations/listOrders"
  )
);
const CreateInvestigations = lazy(() =>
  import(
    /* webpackChunkName: 'CreateInvestigations' */ "./pages/investigations"
  )
);
const UploadInvestigations = lazy(() =>
  import(
    /* webpackChunkName: 'UploadInvestigations' */ "./components/investigations/upload"
  )
);
const InvestigationResults = lazy(() =>
  import(
    /* webpackChunkName: 'InvestigationResults' */ "./components/investigations/results"
  )
);
const Dashboard = lazy(() =>
  import(/* webpackChunkName: 'Dashboard' */ "./pages/dashboard")
);
// const Prescription = lazy(() =>
//   import(/* webpackChunkName: 'Prescription' */ "./pages/prescription")
// );
const Patient = lazy(() =>
  import(/* webpackChunkName: 'Patient' */ "./pages/patient")
);
const ViewAllSPO2 = lazy(() =>
  import(/* webpackChunkName: 'ViewAllSPO2' */ "./components/com/viewAllSPO2")
);
const ViewAllPatients = lazy(() =>
  import(
    /* webpackChunkName: 'ViewAllPatients' */ "./components/com/viewAllPatients.jsx"
  )
);
const TriagePDF = lazy(() =>
  import(/* webpackChunkName: 'TriagePDF' */ "./pages/triagePDF.js")
);
// const BedAvailabilty = lazy(() =>
//   import(
//     /* webpackChunkName: 'BedAvailabilty' */ "./components/com/bedAvailabilty"
//   )
// );
// const Nurse = lazy(() =>
//   import(/* webpackChunkName: 'Nurse' */ "./pages/nurse")
// );
const AllTriage = lazy(() =>
  import(
    /* webpackChunkName: 'AllTriage' */ "./components/com/viewAllTriage.jsx"
  )
);
const MedicationPlan = lazy(() =>
  import(/* webpackChunkName: 'MedicationPlan' */ "./pages/medicationPlan")
);
const MedicationHistory = lazy(() =>
  import(
    /* webpackChunkName: 'MedicationHistory' */ "./pages/medicationHistory"
  )
);
const PatientCondition = lazy(() =>
  import(/* webpackChunkName: 'PatientCondition' */ "./pages/patientCondition")
);
const Discharge = lazy(() =>
  import(/* webpackChunkName: 'Discharge' */ "./pages/discharge")
);
const UserSetup = lazy(() =>
  import(/* webpackChunkName: 'UserSetup' */ "./pages/userSetup")
);
const WardSetup = lazy(() =>
  import(/* webpackChunkName: 'WardSetup' */ "./pages/wardSetup")
);
const HospitalDetails = lazy(() =>
  import(
    /* webpackChunkName: 'HospitalDetails' */ "./components/hospitalDetailsEdit"
  )
);
const Settings = lazy(() =>
  import(/* webpackChunkName: 'Settings' */ "./pages/settings")
);
const CreateInvestigationsAdmin = lazy(() =>
  import(
    /* webpackChunkName: 'CreateInvestigations' */ "./pages/createInvestigations"
  )
);
const ShowInvestigationsAdmin = lazy(() =>
  import(
    /* webpackChunkName: 'ShowInvestigations' */ "./pages/showInvestigations"
  )
);

axios.interceptors.request.use(
  function (config) {
    // config.headers.token = localStorage.token;
    config.headers["Authorization"] = `Bearer ${localStorage.token}`;
    return config;
  },
  function (error) {
    // openNotification("error", `Something went wrong`);
    console.log("error", error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // if (response.status === 401) {
    //   //TODO: API returns "token Missing" for other things too. So can't use response interceptor for route gaurding
    //   localStorage.clear();
    //   window.location.href = "/";
    // }
    return response;
  },
  function (error) {
    if (error.message.includes("401")) {
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);
const ProtectedRoutes = (props) => {
  let history = useHistory();
  if (!localStorage.token) {
    // TODO remove this before prod
    history.push(`/`);
    return props.children;
  } else {
    return props.children;
  }
};
function RenderMenu(props) {
  const [selectedMenu, setSelectedMenu] = useState([]);
  let history = useHistory();
  let location = useLocation();

  function handleClick({ item, key, keyPath, domEvent }) {
    if (key === "logout") {
      props.onLogout();
      localStorage.clear();
      history.push(`/`);
    } else if (key === "reports") {
      props.openReportsDrawer();
    } else {
      setSelectedMenu([key]);
      history.push(`/${key}`);
    }
  }
  useEffect(() => {
    if (location.pathname !== "/" && props.responsibilities.length === 0) {
      props.fetchResponsibility();
    }
  }, [location.pathname, props]);
  useEffect(() => {
    switch (location.pathname) {
      case "/triagePatients":
        return setSelectedMenu(["triagePatients"]);
      case "/landing":
        return setSelectedMenu(["landing"]);
      case "/settings":
        return setSelectedMenu(["sub1"]);
      case "/dashboard":
        return setSelectedMenu(["dashboard"]);
      default:
        return setSelectedMenu([]);
    }
    // if (props.role_id === 4) {
    //   setSelectedMenu(["dashboard"]);
    // } else {
    //   setSelectedMenu(["landing"]);
    // }
  }, [location.pathname, props.role_id]);

  if (props.role_id === 4) {
    return (
      <>
        <Layout.Header>
          {location.pathname !== "/" ? (
            <Menu
              theme="dark"
              mode="horizontal"
              onClick={({ item, key, keyPath, domEvent }) =>
                handleClick({ item, key, keyPath, domEvent })
              }
              selectedKeys={selectedMenu}
            >
              <Menu.Item key="logo" disabled>
                <div className="logo"></div>
              </Menu.Item>
              <Menu.Item key="dashboard" icon={<BarChartOutlined />}>
                Dashboard
              </Menu.Item>
              <Menu.Item key="landing" icon={<SolutionOutlined />}>
                Detailed View
              </Menu.Item>
              <Menu.Item key="criticalSPO2" icon={<AlertOutlined />}>
                Pts w/ Critical SPo2
              </Menu.Item>
              <Menu.Item key="allPatients" icon={<TeamOutlined />}>
                All Patients
              </Menu.Item>
              <Menu.Item key="triagePatients" icon={<TeamOutlined />}>
                Triage Patients
              </Menu.Item>
              <Menu.SubMenu
                key="sub1"
                icon={<UserOutlined />}
                title={localStorage.getItem("display_name")}
                style={{ float: "right" }}
              >
                <Menu.Item key="logout" icon={<LogoutOutlined />}>
                  Logout
                </Menu.Item>
                <Menu.Item key="version" disabled>
                  v{packageJson.version}
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
          ) : null}
        </Layout.Header>
      </>
    );
  } else {
    return (
      <>
        <Layout.Header>
          {location.pathname !== "/" ? (
            <Menu
              theme="dark"
              mode="horizontal"
              onClick={({ item, key, keyPath, domEvent }) =>
                handleClick({ item, key, keyPath, domEvent })
              }
              selectedKeys={selectedMenu}
            >
              <Menu.Item key="logo" disabled>
                <div className="logo"></div>
              </Menu.Item>
              <Menu.Item key="landing" icon={<SolutionOutlined />}>
                All Patients
              </Menu.Item>
              <Menu.Item key="triagePatients" icon={<TeamOutlined />}>
                Triage Patients
              </Menu.Item>
              <Menu.Item
                key="reports"
                icon={<AuditOutlined />}
                hidden={!props.reportsResponsibility}
              >
                Reports
              </Menu.Item>
              {/* {props.role_id === 1 ? (
                <>
                  {props.reportsResponsibility ? (
                    <Menu.Item key="reports">Reports</Menu.Item>
                  ) : null}
                </>
              ) : null} */}
              <Menu.SubMenu
                key="sub1"
                icon={<UserOutlined />}
                title={
                  <>
                    {localStorage.getItem("display_name")} <DownOutlined />
                  </>
                }
                style={{ float: "right" }}
              >
                {props.role_id === 1 ? (
                  <Menu.Item key="settings" icon={<SettingOutlined />}>
                    Settings
                  </Menu.Item>
                ) : null}
                <Menu.Item key="logout" icon={<LogoutOutlined />}>
                  Logout
                </Menu.Item>
                <Menu.Item key="version" disabled>
                  <Tag>v{packageJson.version}</Tag>
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
          ) : null}
        </Layout.Header>
      </>
    );
  }
}

export const ClinicContext = React.createContext();
ClinicContext.displayName = "ClinicContext";

export default class Main extends Component {
  constructor(props) {
    super(props);
    this.downloadCollectorRef = React.createRef();
    this.downloadLANRef = React.createRef();
    this.downloadCSRef = React.createRef();
    this.downloadCSRefCSV = React.createRef();
    this.downloadFormOneRef = React.createRef();
    this.downloadFormOneRefCSV = React.createRef();
    this.downloadDayWiseRef = React.createRef();
    this.downloadDayWiseRefCSV = React.createRef();
    this.downloadSariSurveRef = React.createRef();
    this.downloadSariSurveRefCSV = React.createRef();
    this.downloadSariLineListeRef = React.createRef();
    this.downloadSariLineListeRefCSV = React.createRef();

    this.updateItem = (key, value) => {
      this.setState({ [key]: value });
    };
    this.state = {
      doctors: [],
      doctorId: null,
      clinicId: null,
      doctorName: "",
      collapsed: true,
      hospital_title: "",
      role_id: Number(localStorage.getItem("role_id")),
      update: (key, value) => this.updateItem(key, value),
      // report,
      reportDrawer: false,
      fileUrlCollector: null,
      fileUrlLAN: null,
      fileUrlCS: null,
      CSVfileUrlCS: null,
      fileUrlFormOne: null,
      CSVfileUrlFormOne: null,
      fileUrlDayWise: null,
      CSVfileUrlDayWise: null,

      fileUrlSariSurv: null,
      CSVfileUrlSariSurv: null,

      fileUrlSariLineList: null,
      CSVfileUrlSariLineList: null,

      responsibility: [],
      reports: [],
      reportsResponsibility: false,
      // loading
      collectorReportLoading: false,
      patientListLoading: false,
      csReportLoading: false,
      FormOneReportLoading: false,
      DayWiseReportLoading: false,
      sariSurvReportLoading: false,
      sariLineListReportLoading: false,
    };
  }
  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };
  onLogout = () => {
    this.setState({
      hospital_title: "",
      responsibility: [],
      reports: [],
      reportsResponsibility: false,
    });
  };
  renderTitle = () => {
    return (
      <h3
        style={{
          marginBottom: "1rem",
        }}
      >
        {this.state.hospital_title}
        {" v"}
        <small>{packageJson.version}</small>
      </h3>
    );
  };
  downloadCollectorReport = () => {
    this.setState({ collectorReportLoading: true });
    axios
      .get(`${API_ROOT}/collector_report`)
      .then((res) => {
        this.setState(
          {
            fileUrlCollector: res.data.file_url,
            collectorReportLoading: false,
          },
          () => this.downloadCollectorRef.current.click()
        );
      })
      .catch((err) => {
        this.setState({ collectorReportLoading: false });
        message.error(err);
      });
  };
  downloadLANReport = () => {
    this.setState({ patientListLoading: true });
    axios
      .get(`${API_ROOT}/room_lan_report`)
      .then((res) => {
        this.setState(
          { fileUrlLAN: res.data.file_url, patientListLoading: false },
          () => this.downloadLANRef.current.click()
        );
      })
      .catch((err) => {
        this.setState({ patientListLoading: false });
        message.error(err);
      });
  };
  downloadCSReport = () => {
    this.setState({ csReportLoading: true });
    axios
      .get(`${API_ROOT}/cs_report`)
      .then((res) => {
        console.log("csreport", res.data);
        this.setState(
          {
            fileUrlCS: res.data.file_url,
            CSVfileUrlCS: res.data.csv_file_url,
            csReportLoading: false,
          },
          () => {
            this.downloadCSRef.current.click();
            this.downloadCSRefCSV.current.click();
          }
        );
      })
      .catch((err) => {
        this.setState({ csReportLoading: false });
        message.error(err);
      });
  };
  downloadFormOne = () => {
    this.setState({ FormOneReportLoading: true });
    axios
      .get(`${API_ROOT}/form1_salem`)
      .then((res) => {
        this.setState(
          {
            fileUrlFormOne: res.data.file_url,
            CSVfileUrlFormOne: res.data.csv_file_url,
            FormOneReportLoading: false,
          },
          () => {
            this.downloadFormOneRef.current.click();
            this.downloadFormOneRefCSV.current.click();
          }
        );
      })
      .catch((err) => {
        this.setState({ FormOneReportLoading: false });
        message.error(err);
      });
  };
  downloadDayWise = () => {
    this.setState({ DayWiseReportLoading: true });
    axios
      .get(`${API_ROOT}/day_wise_report`)
      .then((res) => {
        this.setState(
          {
            fileUrlDayWise: res.data.file_url,
            CSVfileUrlDayWise: res.data.csv_file_url,
            DayWiseReportLoading: false,
          },
          () => {
            this.downloadDayWiseRef.current.click();
            this.downloadDayWiseRefCSV.current.click();
          }
        );
      })
      .catch((err) => {
        this.setState({ DayWiseReportLoading: false });
        message.error(err);
      });
  };
  downloadSariSurv = () => {
    this.setState({ sariSurvReportLoading: true });
    axios
      .get(`${API_ROOT}/sari_surveillance`)
      .then((res) => {
        this.setState(
          {
            fileUrlSariSurv: res.data.file_url,
            CSVfileUrlSariSurv: res.data.csv_file_url,
            sariSurvReportLoading: false,
          },
          () => {
            this.downloadSariSurveRef.current.click();
            this.downloadSariSurveRefCSV.current.click();
          }
        );
      })
      .catch((err) => {
        this.setState({ sariSurvReportLoading: false });
        message.error(err);
      });
  };
  downloadSariLineList = () => {
    this.setState({ sariLineListReportLoading: true });
    axios
      .get(`${API_ROOT}/sari_surveillance`)
      .then((res) => {
        this.setState(
          {
            fileUrlSariLineList: res.data.file_url,
            CSVfileUrlSariLineList: res.data.csv_file_url,
            sariLineListReportLoading: false,
          },
          () => {
            this.downloadSariLineListeRef.current.click();
            this.downloadSariLineListeRefCSV.current.click();
          }
        );
      })
      .catch((err) => {
        this.setState({ sariLineListReportLoading: false });
        message.error(err);
      });
  };
  toggleReportDrawer = () => {
    if (this.state.responsibility.length === 0) {
      this.fetchResponsibility();
    }
    this.setState((state) => ({ reportDrawer: !state.reportDrawer }));
  };
  onDownloadBtnClick = (key) => {
    if (key === "collector") {
      this.downloadCollectorReport();
    }
    if (key === "lan") {
      this.downloadLANReport();
    }
    if (key === "csReport") {
      this.downloadCSReport();
    }
    if (key === "form1") {
      this.downloadFormOne();
    }
    if (key === "dayWise") {
      this.downloadDayWise();
    }
    if (key === "sariSurv") {
      this.downloadSariSurv();
    }
    if (key === "sariLineList") {
      this.downloadSariLineList();
    }
  };
  getLoading = (key) => {
    if (key === "collector") {
      return this.state.collectorReportLoading;
    }
    if (key === "lan") {
      return this.state.patientListLoading;
    }
    if (key === "csReport") {
      return this.state.csReportLoading;
    }
    if (key === "form1") {
      return this.state.FormOneReportLoading;
    }
    if (key === "dayWise") {
      return this.state.DayWiseReportLoading;
    }
    if (key === "sariSurv") {
      return this.state.sariSurvReportLoading;
    }
    if (key === "sariLineList") {
      return this.state.sariLineListReportLoading;
    }
  };
  fetchResponsibility = () => {
    let responsibilitiesFromCache = localStorage.getItem("responsibilities");
    let parseCache = JSON.parse(responsibilitiesFromCache);
    let cacheExists = !isEmpty(parseCache);
    if (!cacheExists) {
      axios
        .get(`${API_ROOT}/responsibility`)
        .then((res) => {
          this.setResponsibilities(res.data.responsibility);
        })
        .catch((err) => message.error(`${err}`));
    } else {
      this.setResponsibilities(parseCache);
    }
  };
  setResponsibilities = (responsibilities) => {
    let checkReports = responsibilities.filter((x) => x.is_question === 2);
    let exists = !isEmpty(checkReports);
    this.setState(
      {
        responsibility: responsibilities,
        reports: checkReports,
        reportsResponsibility: exists,
      },
      () =>
        localStorage.setItem(
          "responsibilities",
          JSON.stringify(responsibilities)
        )
    );
  };

  componentDidMount() {
    this.setState({
      hospital_title: localStorage.getItem("hospital_title"),
    });
  }

  render() {
    // const PropedAuth = (props) => <Auth update={this.updateItem} {...props} />;
    const checkIfaReportExists = (report_id) => {
      let theCheck = this.state.reports.find(
        (report) => report.id === report_id
      );
      let title = theCheck ? theCheck.title : null
      return [!isUndefined(theCheck), title];
    }
    return (
      <main style={{ minHeight: "100vh" }}>
        <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <ClinicContext.Provider value={this.state}>
                <Layout style={{ minHeight: "100vh" }}>
                  <RenderMenu
                    onLogout={this.onLogout}
                    role_id={this.state.role_id}
                    openReportsDrawer={this.toggleReportDrawer}
                    reportsResponsibility={this.state.reportsResponsibility}
                    responsibilities={this.state.responsibility}
                    fetchResponsibility={this.fetchResponsibility}
                  />
                  <ErrorBoundary {...this.props}>
                    <Layout.Content
                      style={{
                        margin: "1rem 0rem",
                        overflow: "auto",
                      }}
                    >
                      {/* {this.renderTitle()} */}
                      <MiniDeet
                        hospital_title={this.state.hospital_title}
                        version={packageJson.version}
                      />

                      <Route exact path="/" component={Auth} />
                      <ProtectedRoutes>
                        <Route exact path="/landing" component={Landing} />
                        <Route
                          exact
                          path="/patient/:patient_id"
                          component={PatientDetail}
                        />
                        <Route
                          exact
                          path="/addpatient"
                          component={Demographic}
                          hideAudit
                        />
                        <Route
                          exact
                          path="/patient/:patient_id/:responsibility_id/demographic"
                          component={Demographic}
                        />
                        <Route
                          exact
                          path="/patient/:patient_id/:responsibility_id/hos"
                          component={Hos}
                        />
                        <Route
                          exact
                          path="/patient/:patient_id/:responsibility_id/condition"
                          component={PatientCondition}
                        />
                        <Route
                          exact
                          path="/patient/:patient_id/:responsibility_id/comorbidity"
                          component={Comorbidity}
                        />
                        <Route
                          exact
                          path="/patient/:patient_id/:responsibility_id/hospitalization"
                          component={Hospitalization}
                        />
                        <Route
                          exact
                          path="/patient/:patient_id/:responsibility_id/investigations/orders"
                          component={ListOrders}
                        />
                        <Route
                          exact
                          path="/patient/:patient_id/:responsibility_id/investigations/orders/new"
                          component={CreateInvestigations}
                        />
                        <Route
                          exact
                          path="/patient/:patient_id/:responsibility_id/investigations/orders/upload"
                          component={UploadInvestigations}
                        />
                        <Route
                          exact
                          path="/patient/:patient_id/:responsibility_id/investigations/orders/results"
                          component={InvestigationResults}
                        />
                        <Route
                          exact
                          path="/patient/:patient_id/:responsibility_id/medication/plan"
                          component={MedicationPlan}
                        />
                        <Route
                          exact
                          path="/patient/:patient_id/:responsibility_id/medication/history"
                          component={MedicationHistory}
                        />
                        <Route
                          exact
                          path="/patient/:patient_id/:responsibility_id/discharge"
                          component={Discharge}
                        />
                        <Route
                          exact
                          path="/triagePatients"
                          component={AllTriage}
                        />
                        <Route
                          exact
                          path="/patient/:id/triagePDF"
                          component={TriagePDF}
                        />
                        <Route
                          exact
                          path="/patient/:id/detail"
                          component={Patient}
                        />
                        <Route exact path="/dashboard" component={Dashboard} />
                        <Route
                          exact
                          path="/criticalSPO2"
                          component={ViewAllSPO2}
                        />
                        <Route
                          exact
                          path="/allPatients"
                          component={ViewAllPatients}
                        />
                        <Route exact path="/userSetup" component={UserSetup} />
                        <Route exact path="/wardSetup" component={WardSetup} />
                        <Route
                          exact
                          path="/hospitalDetails"
                          component={HospitalDetails}
                        />
                        <Route exact path="/settings" component={Settings} />
                        <Route
                          exact
                          path="/investigations/setup"
                          component={CreateInvestigationsAdmin}
                        />
                        <Route
                          exact
                          path="/investigations/create"
                          component={ShowInvestigationsAdmin}
                        />

                        {/* <Route
                        exact
                        path="/allPatients"
                        component={TreatmentHistory}
                      /> */}
                      </ProtectedRoutes>
                    </Layout.Content>
                  </ErrorBoundary>
                </Layout>
              </ClinicContext.Provider>
            </Switch>
          </Suspense>
        </BrowserRouter>
        <Drawer
          title="Reports"
          placement="right"
          closable={true}
          onClose={this.toggleReportDrawer}
          visible={this.state.reportDrawer}
          width="50%"
          destroyOnClose
        >
          {this.state.fileUrlCollector !== null && (
            <a
              href={this.state.fileUrlCollector}
              download
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "none" }}
              ref={this.downloadCollectorRef}
            >
              Download
            </a>
          )}
          {this.state.fileUrlLAN !== null && (
            <a
              href={this.state.fileUrlLAN}
              download
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "none" }}
              ref={this.downloadLANRef}
            >
              Download
            </a>
          )}
          {/* CSreport */}
          {this.state.fileUrlCS !== null && (
            <a
              href={this.state.fileUrlCS}
              download
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "none" }}
              ref={this.downloadCSRef}
            >
              Download
            </a>
          )}
          {this.state.CSVfileUrlCS !== null && (
            <a
              href={this.state.CSVfileUrlCS}
              download
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "none" }}
              ref={this.downloadCSRefCSV}
            >
              Download
            </a>
          )}
          {/* Form 1 - Salem */}
          {this.state.fileUrlFormOne !== null && (
            <a
              href={this.state.fileUrlFormOne}
              download
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "none" }}
              ref={this.downloadFormOneRef}
            >
              Download
            </a>
          )}
          {this.state.CSVfileUrlFormOne !== null && (
            <a
              href={this.state.CSVfileUrlFormOne}
              download
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "none" }}
              ref={this.downloadFormOneRefCSV}
            >
              Download
            </a>
          )}
          {/* DayWise report */}
          {this.state.fileUrlDayWise !== null && (
            <a
              href={this.state.fileUrlDayWise}
              download
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "none" }}
              ref={this.downloadDayWiseRef}
            >
              Download
            </a>
          )}
          {this.state.CSVfileUrlDayWise !== null && (
            <a
              href={this.state.CSVfileUrlDayWise}
              download
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "none" }}
              ref={this.downloadDayWiseRefCSV}
            >
              Download
            </a>
          )}
          {/* sari Surveillance report */}
          {this.state.fileUrlSariSurv !== null && (
            <a
              href={this.state.fileUrlSariSurv}
              download
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "none" }}
              ref={this.downloadSariSurveRef}
            >
              Download
            </a>
          )}
          {this.state.CSVfileUrlSariSurv !== null && (
            <a
              href={this.state.CSVfileUrlSariSurv}
              download
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "none" }}
              ref={this.downloadSariSurveRefCSV}
            >
              Download
            </a>
          )}
          {/* sari line list report */}
          {this.state.fileUrlSariLineList !== null && (
            <a
              href={this.state.fileUrlSariLineList}
              download
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "none" }}
              ref={this.downloadSariLineListeRef}
            >
              Download
            </a>
          )}
          {this.state.CSVfileUrlSariLineList !== null && (
            <a
              href={this.state.CSVfileUrlSariLineList}
              download
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "none" }}
              ref={this.downloadSariLineListeRefCSV}
            >
              Download
            </a>
          )}
          <List
            dataSource={[
              {
                name: checkIfaReportExists(28)[1],
                key: "collector",
                exists: checkIfaReportExists(28)[0],
              },
              {
                name: checkIfaReportExists(29)[1],
                key: "lan",
                exists: checkIfaReportExists(29)[0],
              },
              {
                name: checkIfaReportExists(31)[1],
                key: "csReport",
                exists: checkIfaReportExists(31)[0],
              },
              {
                name: checkIfaReportExists(32)[1],
                key: "form1",
                exists: checkIfaReportExists(32)[0],
              },
              {
                name: checkIfaReportExists(33)[1],
                key: "dayWise",
                exists: checkIfaReportExists(33)[0],
              },
              {
                name: checkIfaReportExists(34)[1],
                key: "sariSurv",
                exists: checkIfaReportExists(34)[0],
              },
              {
                name: checkIfaReportExists(35)[1],
                key: "sariLineList",
                exists: checkIfaReportExists(35)[0],
              },
            ]}
            bordered
            renderItem={(item) => (
              <>
                {item.exists ? (
                  <List.Item
                    key={item.key}
                    actions={[
                      <Button
                        onClick={() => this.onDownloadBtnClick(item.key)}
                        key={`a-${item.key}`}
                        icon={<CloudDownloadOutlined />}
                        type="primary"
                        loading={this.getLoading(item.key)}
                      >
                        {/* Download */}
                        {this.getLoading(item.key) === true
                          ? "Downloading"
                          : "Download"}
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta title={item.name} />
                  </List.Item>
                ) : null}
              </>
            )}
          />
        </Drawer>
      </main>
    );
  }
}
